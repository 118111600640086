import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-plugin-sanity-image"

import Layout from "../../components/layout"

const pages = [
  {
    title: "Bokoroki",
    href: "/denizens/bokoary",
    imageUrl:
      "https://media-waterdeep.cursecdn.com/avatars/thumbnails/7996/753/1000/1000/637115020139998917.jpeg",
  },
  {
    title: "Divergent Kin",
    href: "/denizens/divergent-kin",
    imageUrl:
      "https://media-waterdeep.cursecdn.com/avatars/thumbnails/9/843/1000/1000/636332311073165771.jpeg",
  },
  {
    title: "Latter Kin",
    href: "/denizens/latter-kin",
    imageUrl:
      "https://media-waterdeep.cursecdn.com/avatars/thumbnails/0/13/1000/1000/636238871029832086.jpeg",
  },
  {
    title: "Summons",
    href: "/denizens/summons",
    imageUrl:
      "https://media-waterdeep.cursecdn.com/avatars/thumbnails/266/330/1000/1000/636596677808633175.png",
  },
]

const DenizensPage = ({ data }) => {
  const denizens = data.allSanityDenizen.nodes

  // console.log(data)

  return (
    <Layout>
      <div className="bg-texture px-6 lg:px-0 overflow-hidden">
        <div className="mt-12 mx-auto">
          <h1
            className="text-center text-mocha font-display  text-5xl"
            style={{ fontWeight: 700 }}
          >
            Denizens
          </h1>
        </div>

        <div className="mt-5 max-w-7xl mx-auto flex flex-wrap justify-center space-y-8 space-x-0 sm:space-y-12 sm:space-x-12">
          {denizens.map(_page => (
            <div className="relative group w-full sm:w-2/5">
              <Link to={`/denizens/${_page.slug.current}`}>
                <div className="h-96 sm:h-112">
                  <Image
                    {..._page.image}
                    alt={`${_page.name} Avatar`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  {/* <img className="w-full h-full object-cover object-top" src={page.image.asset.fluid.src} alt={`${page.name} Card Image`} /> */}
                </div>
                <div
                  className="absolute bottom-0 w-full h-12 flex items-center justify-center bg-mocha group-hover:bg-mocha-light transition duration-300 ease-in-out"
                  style={{ borderRadius: "0.5rem" }}
                >
                  <span className="text-center text-xl text-white font-display tracking-wide">
                    {_page.name}
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default DenizensPage

export const query = graphql`
  query AllDenizens {
    allSanityDenizen(sort: { fields: [name], order: ASC }) {
      nodes {
        _id
        name
        slug {
          current
        }
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`
